import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

// Import the new LoadingSpinner component
import LoadingSpinner from './components/LoadingSpinner';

const MainPage = lazy(() => import('./MainPage'));
const RegistrationForm = lazy(() => import('./RegistrationForm'));
const SubmissionDetails = lazy(() => import('./SubmissionDetails'));
const CheckSubmissionStatus = lazy(() => import('./CheckSubmissionStatus'));
const Ranking = lazy(() => import('./Ranking'));
const AdminLogin = lazy(() => import('./AdminLogin'));
const AdminPanel = lazy(() => import('./AdminPanel'));
const AdminSubmissionDetails = lazy(() => import('./AdminSubmissionDetails'));
const AdminUserManagement = lazy(() => import('./AdminUserManagement'));
const FAQManager = lazy(() => import('./components/FAQManager'));
const ApprovedSubmissions = lazy(() => import('./components/ApprovedSubmissions'));

function PrivateRoute({ children }) {
  const isAuthenticated = localStorage.getItem('adminToken') !== null;
  return isAuthenticated ? children : <Navigate to="/admin/login" />;
}

// New component to handle Google Analytics
function GoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
}

ReactGA.initialize("G-PHVLCJ9T5J"); // Replace with your Measurement ID

function App() {
  return (
    <Router>
      <GoogleAnalytics />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/faq" element={<Navigate to="/#faq" replace />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/submission/:urlIdentifier" element={<SubmissionDetails />} />
          <Route path="/check-status" element={<CheckSubmissionStatus />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/panel" element={<PrivateRoute><AdminPanel /></PrivateRoute>} />
          <Route path="/admin/submission/:id" element={<PrivateRoute><AdminSubmissionDetails /></PrivateRoute>} />
          <Route path="/admin/users" element={<PrivateRoute><AdminUserManagement /></PrivateRoute>} />
          <Route path="/admin/faq-manager" element={<PrivateRoute><FAQManager /></PrivateRoute>} />
          <Route path="/admin/approved-submissions" element={<PrivateRoute><ApprovedSubmissions /></PrivateRoute>} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
